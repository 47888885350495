





























import Vue from "vue";
import { mapState } from "vuex";
import { dispatch } from "@/store";

export default Vue.extend({
  computed: {
    ...mapState("cart", {
      count: "count",
      query: "query",
    }),
    ...mapState("profile", {
      uuid: "uuid",
    }),
    productId(): number {
      const { id } = this.$route.params;
      return id ? Number(id) : 0;
    },
  },
  methods: {
    refreshCart() {
      if (this.$route.path !== this.$paths.cart && this.uuid) {
        dispatch.cartGetList(this.query);
      }
    },
  },
  watch: {
    $route(to, frome) {
      if (to.path !== frome.path) {
        this.refreshCart();
      }
    },
  },
  mounted() {
    this.refreshCart();
  },
});
